import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"

import BlockContentAlert from "./BlockContentAlert"
import { AiOutlineCloseCircle } from "react-icons/ai"
import { AlertContext } from "./context/AlertContext"

// const CloseIcon = ({ setItem, item }) => {
//   // check if it's a browser
//   // i.e. if window exists, then it's a browser (local storage doesn't exist in server-side component)
//   const isBrowser = typeof window !== "undefined"

//   return (
//     <button
//       className="pl-4"
//       onClick={() => {
//         setItem(false)
//         if (isBrowser) {
//           localStorage.setItem("banner_visible", false)
//         }
//       }}
//     >
//       X
//     </button>
//   )
// }

const AlertBanner = () => {
  const alert = useContext(AlertContext)

  const { sanityAlertBanner } = useStaticQuery(graphql`
    query AlertBannerQuery {
      sanityAlertBanner {
        activeBanner
        _rawAnnouncementText(resolveReferences: { maxDepth: 10 })
      }
    }
  `)

  return (
    <>
      {sanityAlertBanner.activeBanner && alert.isAlertVisible ? (
        <div className="bg-red-500 text-white flex items-center justify-center text-2xl text-center relative ">
          {sanityAlertBanner._rawAnnouncementText ? (
            <div className="p-6  w-full grid-flow-col  justify-items-center ">
              <BlockContentAlert
                blockData={sanityAlertBanner._rawAnnouncementText}
                className="self-center border"
              />
              <button
                className=" absolute top-0 right-0 justify-self-end mt-2 mr-2"
                title="Dismiss"
                onClick={() => alert.closeAlert()}
              >
                <AiOutlineCloseCircle className=" hover:text-yellow-500" />
              </button>
            </div>
          ) : null}
          {/* <CloseIcon setItem={setBannerVisible} item={bannerVisible} /> */}
        </div>
      ) : null}
    </>
  )
}

export default AlertBanner
