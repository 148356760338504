import { Link } from "gatsby"
import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FaFacebook } from "react-icons/fa"
import Img from "gatsby-image"

const FooterTitle = ({ children, className = "" }) => (
  <h3 className={`text-4xl ${className}`}>{children}</h3>
)

// standard container for general text in the footer
// fontsize accepts a tailwind class for text size
const FooterTextContainer = ({ children, fontSize = "", className = "" }) => (
  <div className={`${fontSize || "text-xl"}  grid row-gap-5 ${className}  `}>
    {children}
  </div>
)

const FooterLinksList = () => (
  <article className="footer__links hidden lg:block">
    <ul className="footer__links__list">
      <li>
        <Link to="/" className="footer__links__listItem">
          Home
        </Link>
      </li>
      <li>
        <span className="footer__links__divider">|</span>
      </li>
      <li>
        <Link to="/about" className="footer__links__listItem">
          About Us
        </Link>
      </li>
      <li>
        <span className="footer__links__divider">|</span>
      </li>
      <li>
        <Link to="/virtual-tours" className="footer__links__listItem">
          Virtual Tours
        </Link>
      </li>
      <li>
        <span className="footer__links__divider">|</span>
      </li>
      <li>
        <Link to="/past-events" className="footer__links__listItem">
          Past Events
        </Link>
      </li>
      <li>
        <span className="footer__links__divider">|</span>
      </li>
      <li>
        <Link to="/news" className="footer__links__listItem">
          News
        </Link>
      </li>
      <li>
        <span className="footer__links__divider">|</span>
      </li>
      <li>
        <Link to="/contact" className="footer__links__listItem">
          Contact Us
        </Link>
      </li>
      <li>
        <span className="footer__links__divider">|</span>
      </li>
      <li>
        <Link to="/" className="footer__links__listItem">
          NOFNEC © {new Date().getFullYear()}
        </Link>
      </li>
    </ul>
  </article>
)

// Primary component

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      allSanityParticipant(filter: { active: { eq: true } }) {
        edges {
          node {
            title
            url
          }
        }
      }

      sanityPlanners {
        contactInfo {
          phone
          email
        }
      }
      sanityLogo: file(relativePath: { eq: "sanity-logo.png" }) {
        id
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  // array of hos organizations
  const hosts = data.allSanityParticipant.edges

  const { sanityPlanners } = data

  return (
    <footer className="text-2xl bg-nofnecGreen">
      {/* Top section of footer */}
      <div className="grid  lg:grid-flow-col justify-center py-10 px-5 md:px-16 gap-x-6 ">
        <section className="hidden md:block">
          <FooterTitle>Hosted By</FooterTitle>

          <p className="mt-5">
            {hosts.map((host, index) => (
              <a
                href={host.node.url}
                key={index}
                className=" hover:text-nofnecRed hover:underline "
                target="_blank"
                rel="noopener noreferrer"
              >
                {host.node.title}
                {index < hosts.length - 1 ? `, ` : `.`}
              </a>
            ))}
          </p>
        </section>
        {/* Mobile view for Hosted By */}
        <article className="text-center   mt-5 md:hidden ">
          <FooterTitle className="">Hosted By</FooterTitle>
          <FooterTextContainer>
            <ul>
              {hosts.map((host, index) => (
                <li key={index} className="pt-5 leading-5">
                  {host.node.title}
                </li>
              ))}
            </ul>
          </FooterTextContainer>
        </article>

        {/* Social links container */}
        <div className="mt-8 lg:mt-0 text-center">
          <FooterTitle className="hover:text-nofnecRed">
            <Link to="/contact">Get In Touch</Link>
          </FooterTitle>
          <FooterTextContainer className="justify-center text-center md:text-left">
            <a
              href="https://www.facebook.com/NOFNEC/"
              target="_blank"
              rel="noopener noreferrer"
              className="grid justify-center transform hover:scale-125 transition ease-in-out duration-100"
            >
              <FaFacebook className="text-5xl  " />
            </a>
            {/* Contact number */}
            {sanityPlanners.contactInfo.phone && (
              <div className="whitespace-no-wrap">
                {/* <FaPhone /> */}
                {sanityPlanners.contactInfo.phone}
              </div>
            )}
            {sanityPlanners.contactInfo.email && (
              <a
                href={`mailto:${sanityPlanners.contactInfo.email}`}
                className="whitespace-no-wrap underline hover:text-blue-600"
              >
                {/* <FaPhone /> */}
                {sanityPlanners.contactInfo.email}
              </a>
            )}
            {/* Sanity.io Logo and Link */}

            <a
              href="https://www.sanity.io"
              target="_blank"
              rel="noopener noreferrer"
              className="grid justify-items-center lg:justify-items-start row-gap-2 mt-8 md:mt-5"
            >
              <div className="pb-2">Structured content powered by:</div>
              <Img
                fixed={data.sanityLogo.childImageSharp.fixed}
                className=" justify-self-center"
              />
            </a>
          </FooterTextContainer>
        </div>
      </div>

      <FooterLinksList />
    </footer>
  )
}

export default Footer
