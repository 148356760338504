import React from "react"
import PortableText from "@sanity/block-content-to-react"
import { Link } from "gatsby"
import urlBuilder from "@sanity/image-url"
import { sanityConfig } from "../sanityConfig"

const BlockContentAlert = ({ blockData }) => {
  const urlFor = source => urlBuilder(sanityConfig).image(source)

  const serializer = {
    marks: {
      link: props => {
        // console.log(props)
        if (props.mark.blank) {
          return (
            <a
              href={props.mark.href}
              target="_blank"
              rel="noopener noreferrer"
              className="underline text-white hover:text-yellow-500"
            >
              {props.children}
            </a>
          )
        }
        return (
          <a
            href={props.mark.href}
            className="underline text-white hover:text-yellow-500"
          >
            {props.children}
          </a>
        )
      },
      internalPostLink: props => {
        return (
          <span>
            <Link
              className="underline text-white hover:text-yellow-500"
              to={`/news/${props.mark.item.slug.current}`}
            >
              {props.children}
            </Link>
          </span>
        )
      },
      internalPageLink: props => {
        return (
          <Link
            className="underline hover:text-yellow-500"
            to={`${props.mark.handle}`}
          >
            {props.children}
          </Link>
        )
      },
    },
    block: props => {
      const { style = "normal" } = props.node

      // Adds classes to headers so I can target them in the css file (layout.scss)
      // DISABLED B/C TARGETED BELOW
      //  see https://github.com/sanity-io/block-content-to-react#customizing-the-default-serializer-for-block-type
      // if (/^h\d/.test(style)) {
      //   const level = style.replace(/[^\d]/g, "")
      //   return React.createElement(
      //     style,
      //     { className: `blockContent-heading-${level}` },
      //     props.children
      //   )
      // }

      if (style === "normal") {
        return <p className="p-0 m-0 leading-tight">{props.children}</p>
      }
    },
  }

  return (
    <PortableText
      blocks={blockData}
      serializers={serializer}
      className="alert"
    />
  )
}

export default BlockContentAlert
