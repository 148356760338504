import { Link, useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import Img from "gatsby-image"
import { MdMenu, MdClose } from "react-icons/md"

import NavDivider from "./header/NavDivider"

const tourLinks = [
  { name: "Hidden Lake Landfill", to: "/virtual-tours/hiddenlakelandfill/" },
  { name: "Mississauga First Nation", to: "/virtual-tours/mississaugafn/" },
]

const navLinks = [
  {
    name: "Home",
    handle: "",
    key: "home-mobile",
  },
  {
    name: "About",
    handle: "about",
    key: "about-mobile",
  },

  {
    name: "Registration",
    handle: "registration",
    key: "registration-mobile",
  },
  {
    name: "Exhibitors",
    handle: "exhibitors",
    key: "exhibitors-mobile",
  },
  {
    name: "Virtual Tours",
    handle: null,
    key: "virtual-tours-mobile",
    dropDownLinks: tourLinks,
  },
  {
    name: "Workshops",
    handle: "workshops",
    key: "workshops-mobile",
  },
  // {
  //   name: "Agenda",
  //   handle: "agenda",
  //   key: "agenda",
  // },
  {
    name: "News",
    handle: "news",
    key: "news-mobile",
  },
  {
    name: "Supporters",
    handle: "supporters",
    key: "supporters-mobile",
  },
  {
    name: "Contact Us",
    handle: "contact",
    key: "contact-mobile",
  },
  {
    name: "Past Events",
    handle: "past-events",
    key: "past-events-mobile",
  },
  {
    name: "Photos & Videos",
    handle: "media",
    key: "media-mobile",
  },
]

const NavItem = ({ children, to = null, dropDownLinks = null }) => {
  const [dropdownIsVisible, setDropdownVisible] = useState(false)
  return (
    <li className="header__links__listItem hidden md:inline-block md:text-2xl relative  group cursor-pointer ">
      {to ? (
        <Link
          to={to}
          activeClassName="text-nofnecGreen"
          className="hover:text-nofnecGreen "
        >
          {children}
        </Link>
      ) : (
        <div className="">{children}</div>
      )}
      {!dropdownIsVisible && dropDownLinks ? (
        <div className={`hidden group-hover:block absolute z-30  grid   -ml-3`}>
          <div className="grid  gap-y-2 bg-white bg-opacity-90 mt-4 px-3 pt-3 pb-2">
            {dropDownLinks.map(link => (
              <Link
                to={link.to}
                className="block whitespace-nowrap hover:text-nofnecGreen"
                activeClassName="text-nofnecGreen"
              >
                {link.name}
              </Link>
            ))}
          </div>
        </div>
      ) : null}
    </li>
  )
}

const Header = () => {
  const [burgerMenuVisible, setBurgerMenuVisible] = useState(false)

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      logo: file(relativePath: { eq: "NOFNEC_Logo.png" }) {
        childImageSharp {
          fixed(width: 125) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const toggleBurgerMenu = () => {
    setBurgerMenuVisible(!burgerMenuVisible)
  }

  const burgerIconFormat = "text-4xl text-nofnecGreen cursor-pointer ml-3"
  const logoFixed = data.logo.childImageSharp.fixed

  return (
    <header className="shadow-lg grid">
      <nav className="header__links grid grid-flow-col justify-center lg:justify-start content-center grid-cols-navbar-sm lg:grid-cols-navbar-lg navGridAreas shadow-md">
        {/* burger icon */}

        <button
          className="flex justify-start items-center lg:mr-10 xl:mr-32"
          onClick={() => toggleBurgerMenu()}
          onKeyDown={() => toggleBurgerMenu()}
        >
          {!burgerMenuVisible ? (
            <MdMenu className={`${burgerIconFormat}`} />
          ) : (
            <MdClose className={`${burgerIconFormat}`} />
          )}
        </button>

        {/* Main Navbar content */}
        <div className="nav__content grid grid-flow-col grid-cols-auto-1fr">
          {/* Navbar Title/Logo */}
          <Link to="/" className=" hidden md:block justify-self-start">
            {/* logo */}
            <Img
              fixed={logoFixed}
              style={{ width: 125, marginBottom: "-125px" }}
              className="z-30 mt-1 "
            />
          </Link>
          <ul className="hidden lg:flex  justify-around items-center  leading-normal mx-5">
            {/* <li className="md:mr-3">
            <h1 className="header__siteName text-5xl">
              {data.site.siteMetadata.title}
              <span style={{ fontWeight: 200, fontFamily: "Poppins" }}>
                {year}
              </span>
            </h1>
          </li> */}

            <NavItem to="/">Home</NavItem>

            <NavDivider />

            <NavItem to="/about">About</NavItem>

            <NavDivider />

            <NavItem to="/registration">Registration</NavItem>

            <NavDivider />

            <NavItem to="/supporters">Supporters</NavItem>

            <NavDivider />

            <NavItem to="/news">News</NavItem>

            <NavDivider />

            <NavItem to="/contact">Contact</NavItem>
          </ul>
        </div>
        <Link to="/" className="md:mr-8 md:hidden">
          {/* logo */}
          <Img
            fixed={logoFixed}
            style={{
              width: 125,
              marginBottom: "-125px",
            }}
            className="z-30 mt-1 mr-1 "
          />
        </Link>
      </nav>
      {/* Burger menu, sits below navbar */}
      {/* Underline color: https://stackoverflow.com/questions/61303798/how-can-i-change-the-underline-color-in-tailwind-css */}
      {burgerMenuVisible && (
        <div className="grid text-2xl my-3 max">
          {navLinks.map(linkItem => {
            if (linkItem.handle !== null) {
              return (
                <Link
                  to={`/${linkItem.handle}`}
                  onClick={() => setBurgerMenuVisible()}
                  key={linkItem.key}
                  className="hover:bg-nofnecGreen hover:text-white mx-2 p-2"
                  activeClassName="text-nofnecGreen"
                >
                  {linkItem.name}
                </Link>
              )
            } else {
              return (
                <div>
                  <div className="mx-2 p-2 italic ">Virtual Tours:</div>
                  <div className="ml-5">
                    {tourLinks.map(link => (
                      <Link
                        to={link.to}
                        className="hover:bg-nofnecGreen hover:text-white mx-2 p-2 block"
                        activeClassName="text-nofnecGreen"
                      >
                        {link.name}
                      </Link>
                    ))}
                  </div>
                </div>
              )
            }
          })}
          <a
            href="https://nofnec.sanity.studio/desk"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:bg-nofnecGreen text-nofnecGreen hover:text-white mx-2 p-2"
          >
            Admin Login
          </a>
        </div>
      )}
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
