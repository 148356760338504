import React from "react"

const NavDivider = () => {
  return (
    <li className="hidden md:inline ">
      <span className="header__links__divider">|</span>
    </li>
  )
}

export default NavDivider
