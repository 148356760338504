/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./footer"
import styles from "../styles/components/_layout.scss"
import AlertBanner from "./AlertBanner"

const Layout = ({ children, location, className = "" }) => {
  const isBrowser = typeof window !== "undefined"

  let visibilityLocalData = false

  if (isBrowser) {
    visibilityLocalData = localStorage.getItem("banner_visible") ? false : true
  }

  const [bannerVisible, setBannerVisible] = useState(visibilityLocalData)

  // info and example re: animations see https://github.com/ryanwiemer/gatsby-using-page-transitions/blob/master/src/components/Layout.js

  return (
    <div className={`min-h-screen flex flex-col justify-between ${className}`}>
      {bannerVisible && <AlertBanner />}
      <Header />
      <div className="flex-grow flex flex-col">{children}</div>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
